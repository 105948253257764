<template>
	<div class="mt-5">
		<div v-if="searchStats" class="container">
			<div class="row" id="movie-list">
				<div
					v-for="movie in allMovies.Search"
					:key="movie.imdbID"
					class="col-md-3 my-2"
				>
					<div
						class="card h-100 customHoverAnimation"
						data-bs-toggle="modal"
						data-bs-target="#movieDetailInfo"
						v-on:click="showModal(movie.imdbID)"
					>
						<img :src="movie.Poster" :alt="movie.Title" />
						<div class="card-body">
							<h5 class="card-title">{{ movie.Title }}</h5>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div
			v-if="searchStats"
			class="modal fade"
			id="movieDetailInfo"
			tabindex="-1"
			aria-labelledby="movieDetailContent"
			aria-hidden="true"
		>
			<div class="modal-dialog modal-lg">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="movieDetailContent">
							{{ allDetails.Title }}
						</h5>
						<button
							type="button"
							class="btn-close"
							data-bs-dismiss="modal"
							aria-label="Close"
						></button>
					</div>
					<div class="modal-body">
						<div class="container-fluid">
							<div class="row justify-content-center">
								<div class="col-md-4">
									<img
										:src="allDetails.Poster"
										class="img-fluid"
										:alt="allDetails.Title"
									/>
								</div>
								<div class="col-md-8">
									<div class="card">
										<ul class="list-group list-group-flush">
											<li class="list-group-item">
												<strong>Genre: </strong>{{ allDetails.Genre }}
											</li>
											<li class="list-group-item">
												<strong>Released Date: </strong
												>{{ allDetails.Released }}
											</li>
											<li class="list-group-item">
												<strong>Actors: </strong>{{ allDetails.Actors }}
											</li>
											<li class="list-group-item">
												<strong>Overview: </strong>{{ allDetails.Plot }}
											</li>
											<li class="list-group-item">
												<strong>Rating: </strong>{{ allDetails.imdbRating }}
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="modal-footer"></div>
				</div>
			</div>
		</div>
		<div v-if="!searchStats" class="container text-center">
			<h3>Movie Not Found...</h3>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
	data() {
		return {};
	},
	computed: {
		...mapGetters(["allMovies", "allDetails", "searchStats"]),
	},
	methods: {
		...mapActions(["fetchDetail"]),
		showModal(id) {
			this.fetchDetail(id);
		},
	},
};
</script>

<style>
.customHoverAnimation {
	transform: scale(0.9);
	cursor: pointer;
}

.customHoverAnimation:hover {
	transform: scale(1);
}
</style>
