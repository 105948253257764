import { createApp } from "vue";
import App from "./App.vue";
import store from "./store/store.js";
import router from "./router.js";
import dotenv from "dotenv";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import './registerServiceWorker'

dotenv.config();

const app = createApp(App);
app.use(store);
app.use(router);
app.mount("#app");
