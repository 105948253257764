const state = {
	movies: [],
	details: [],
	isFound: true,
};

const getters = {
	allMovies: (state) => state.movies,
	allDetails: (state) => state.details,
	searchStats: (state) => state.isFound,
};

const actions = {
	async fetchMovies({ commit }, query) {
		if (query == "" || query == undefined) {
			query = "Naruto";
			commit("setStatus", true);
		}
		const response = await fetch(`${process.env.VUE_APP_API_URL}&s=${query}`);
		const result = await response.json();
		if (result.Response == "False") {
			commit("setStatus", false);
		}
		commit("setMovies", result);
	},
	async fetchDetail({ commit }, id) {
		if (id == undefined || id == "") {
			return;
		}
		const response = await fetch(`${process.env.VUE_APP_API_URL}&i=${id}`);
		const result = await response.json();
		commit("setMovieDetails", result);
	},
};

const mutations = {
	setMovies: (state, movies) => (state.movies = movies),
	setMovieDetails: (state, details) => (state.details = details),
	setStatus: (state, stats) => (state.isFound = stats),
};

export default {
	state,
	getters,
	mutations,
	actions,
};
