<template>
	<nav class="navbar navbar-dark bg-dark">
		<div class="container">
			<a class="navbar-brand" href="/">Movie Library</a>
			<form class="d-flex">
				<input
					class="form-control me-2"
					id="searchInput"
					type="search"
					placeholder="Search"
					aria-label="Search"
					v-model="searchQuery"
				/>
				<button
					v-on:click="getMovies"
					class="btn btn-outline-success"
					id="searchButton"
					type="submit"
				>
					<i class="fas fa-magnifying-glass"></i>
				</button>
			</form>
		</div>
	</nav>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
	data() {
		return {
			searchQuery: "",
		};
	},
	methods: {
		...mapActions(["fetchMovies"]),
		getMovies(e) {
			e.preventDefault();
			this.fetchMovies(this.searchQuery);
		},
	},
	computed: mapGetters(["allMovies"]),
	created() {
		this.fetchMovies();
	},
};
</script>

<style></style>
