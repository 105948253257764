import { createRouter, createWebHistory } from "vue-router";
import Home from "./components/screens/Home.vue";

const routes = [
	{
		path: "/",
		name: "home",
		component: Home,
		meta: {
			title: "Movie Library",
		},
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

export default router;
