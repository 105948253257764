import Vuex from "vuex";
import movies from "./modules/movies";

const store = new Vuex.Store({
	modules: {
		movies,
	},
});

export default store;
