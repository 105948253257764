<template>
	<div>
		<Navbar />
		<router-view />
	</div>
</template>

<script>
import Navbar from "./components/Navbar.vue";

export default {
	name: "App",
	components: {
		Navbar,
	},
	watch: {
		$route(to) {
			document.title = to.meta.title;
			if (to.params.title != undefined) {
				let baseTitle = to.meta.title;
				document.title = baseTitle + " | " + to.params.title;
			}
		},
	},
};
</script>

<style>
i {
	color: #fff;
}

a {
	text-decoration: none;
}

@media screen and(max-width: 576px) {
	* {
		overflow-x: hidden;
	}
}
</style>
